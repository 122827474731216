<template>
	<div class="loginBox">
		<van-nav-bar safe-area-inset-top :border="false">
			<template #left>
				<div class="langBtn" @click="handleChangeLang">
					<div class="icon"><img src="@/assets/imgs/new_color/yuyanqiehuan.png" alt="" /></div>
				</div>
			</template>
			<template #right>
				<div class="zhuce" @click="routerToZhuCe()">
					<div class="txt">{{ $t('login.regist') }}</div>
				</div>
			</template>
		</van-nav-bar>

		<!-- <div class="loginBg"><img src="@/assets/imgs/login/signin_bg.png" alt="" /></div> -->
		<div class="loginCon">
			<!-- <div class="icon"><img src="../../assets/imgs/icon/ico.jpg" alt="" /></div> -->
			<div class="icon">
				<img :src="logo_img" alt=""  v-if="logo_img != ''"/>
				<!-- <p>{{ $t("system.WelcometoRainbow") }}</p> -->
			</div>
			<div class="login">
				<div class="loginTab">
					<div @click="setActive('password')" :class="{ mainColor1: activeTab === 'password' }">{{ $t('system.Passwordlogin') }}</div>
					<div @click="setActive('code')" :class="{ mainColor1: activeTab === 'code' }">{{ $t('system.SMSlogin') }}</div>
				</div>
				<components :is="regType"></components>
				<p class="tips">{{ $t("login.userLawB") }}<span class="mainColor1" @click="routerTo('/userLaw')">{{ $t("login.userLaw") }}</span></p>
			</div>
			<!-- <div class="zhuceBox">
				<span class="mainColor1" @click="routerToZhuCe()">{{ $t('login.RegisteredAccountNumber') }}</span>
			</div> -->
		</div>
		<Loading v-if="!logo_img"></Loading>
	</div>
</template>

<script>
import LoginCode from '@/components/login/LoginCode.vue';
import LoginPwd from '@/components/login/LoginPwd.vue';
import Loading from '../../components/Loading/Loading.vue';

export default {
	name: 'login',
	components: {
		LoginCode,
		LoginPwd,
		// RegPhone,
		Loading,
	},
	data() {
		return {
			logo: require('@/assets/imgs/login/login.png'),
			regType: LoginPwd,
			activeTab: 'password',
			accessToken: this.$route.query.code,
			app: this.$route.query.app,
			showPicker: false,
			logo_img: ''
		};
	},
	created() {
		this.GetSystemImgList();
		if (!localStorage.getItem('lang')) {
			// 默认语言
			localStorage.setItem('lang','zh');
		}
		// console.log(this.$route.query.code)
		if (this.$route.query.code) {
			global.accessToken = this.accessToken;
			localStorage.accessToken = this.accessToken;

			// this.$dialog.alert({
			//   title: "提示",
			//   message: localStorage.accessToken + "accessToken"
			// });

			this.$post2('Article/user/api/passport/loginwithpwd', {})
				.then(res => {
					global.userInfo = res.user;
					global.defaultToken = res.user.defaultToken;

					localStorage.phone = res.user.phone;
					localStorage.openid = res.user.openid;
					localStorage.uid = res.user.uid;
					localStorage.app = res.user.app;

					localStorage.userInfo = JSON.stringify(res.user);
					if ((typeof localStorage.phone == 'undefined' || localStorage.phone == 0 || localStorage.phone == '') && localStorage.openid) {
						this.$router.push('/sPhone?bindPhone=1');
					} else {
						this.$router.push({
							path: 'start',
							query: {
								ifhave: false
							}
						});
					}
				})
				.catch(err => {
					alert(err);
				});
		}
	},
	methods: {
		// 前往注册页面
		routerToZhuCe() {
			this.$router.push('/registerPage');
		},
		routerTo(path) {
			this.$router.push(path)
		},
		handleChangeLang() {
			let lang = this.$i18n.locale
			// console.log('handleChangeLang', lang)
			if (lang === 'zh') {
				this.$i18n.locale = 'zhf'
				localStorage.setItem('lang', 'zhf')
			}
			if (lang === 'zhf') {
				this.$i18n.locale = 'en'
				localStorage.setItem('lang', 'en')
			}
			if (lang === 'en') {
				this.$i18n.locale = 'kor'
				localStorage.setItem('lang', 'kor')
			}
			if (lang === 'kor') {
				this.$i18n.locale = 'zh'
				localStorage.setItem('lang', 'zh')
			}
		},
		routerToFortePaw() {
			this.$router.push('/forgetThePassword');
		},

		setActive(tab) {
			this.activeTab = tab;
			if (tab === 'code') {
				this.regType = LoginCode;
			} else if (tab == 'password') {
				this.regType = LoginPwd;
			}
		},
		//获取logo图
		GetSystemImgList() {
			this.$post2('User/Api/Passport/GetSystemImgList', {})
				.then(res => {
					localStorage.setItem('logo_img',res.list[1].url);
					localStorage.setItem('avatar_img',res.list[0].url);
					this.logo_img = res.list[1].url;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>
<style lang="less">
@import url('../../assets/css/login.less');
.loginBox {
	background-color: #FFFFFF;
	height: 100vh;
	font-family: PingFangSC-Regular;
	/* .head {
		display: flex;
		margin: 0 0.72rem 0 0.72rem;
		padding-top: 0.28rem;
		align-items: center;
		justify-content: space-between; */
		.langBtn {
			.icon {
				width: 0.36rem;
				height: 0.3352rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					display: block;
					width: 100%;
				}
			}
		}
		.zhuce {
			.txt {
				text-align: center;
				line-height: 0.51rem;
				color: #EFBA0D;
				font-size: 0.32rem;
				font-weight: 600;
			}
		}
	/* } */
	.loginBg {
		position: absolute;
		z-index: 0;
		left: 0;
		right: 0;
		top: -.8rem;
		height: 100vh;
		overflow: hidden;
		img {
			width: 100%;
			display: block;
		}
	}
	.loginCon {
		position: relative;
		z-index: 1;
	}
	.zhuceBox {
		text-align: center;
		/* position: fixed; */
		/* left: 0; */
		width: 100%;
		/* bottom: 0.5rem; */
		margin-top: 0.5rem;
		padding-bottom: 0.5rem;
		span {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: bold;
			color: #ff4b04;
		}
	}

	.loginCon .icon {
		// padding-top: 1.42rem;
		padding-top: 0.95rem;
		width: 3.15rem;
		height: 3.15rem;
		box-sizing: content-box;
		margin: auto;

		img {
			// width: 3.25rem;
			width: 100%;
			height: 100%;
			display: block;
			margin: auto;
		}
		p {
			color: white;
			font-size: .48rem;
			text-align: center;
		}
	}

	.login {
		// padding: 1rem 1rem 0 1rem;
		padding: 2.04rem 0.67rem 0 0.71rem;
		
		.login_txt {
			position: relative;
			// padding-top: 0.3rem;
			.l_txt {
				font-size: 0.48rem;
				font-weight: 700;
			}
			.yy {
				position: absolute;
				left: 0;
				bottom: 0.05rem;
				z-index: -1;
				background-color: #8A8EFE;
				opacity: 0.2;
				height: 0.15rem;
				width: 1rem;
			}
		}

		.loginTab {
			// width: 4.6rem;
			display: flex;
			align-items: center;
			// height: .70rem;
			// margin: 0 0 0.4rem 0;
			div {
				font-size: 0.32rem;
				// font-family: PingFang SC;
				// font-weight: bold;
				text-align: center;
				color: #999999;
				display: inline-block;
				// width: 1.76rem;
				line-height: .7rem;
				// margin: 0 .2rem;
				margin-right: 0.95rem;
				font-weight: 600;
				&:last-child{
					margin-right: 0rem;
				}
			}

			div.on,
			div.mainColor1{
				color: #333333 !important;
				font-size: 0.44rem;
				font-weight: 600;
			}
		}
		
		.tips {
			color: #999999;
			font-size: .28rem;
			text-align: center;
		}
	}
}
</style>
